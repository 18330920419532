import React from "react";

import { css } from "@emotion/core";

import Layout from "../components/Layout/Layout";
import Slider from "../components/Slider/Slider";
import Dropdown from "../components/Dropdown/Dropdown";
import DashboardTabs from "../components/DashboardTabs/DashboardTabs";

import CourseCard from "../components/CourseCard/CourseCard";
import DynamicBackgroundImage from "../components/shared/DynamicBackgroundImage";
import Help from "../components/Help/Help";
import DashboardFellowship from "../components/DashboardFellowship/DashboardFellowship";
import AccountSettings from "../components/AccountSettings/AccountSettings";
import CoursesStartingSoon from "../components/CoursesStartingSoon/CoursesStartingSoon";
import { theme } from "../utils/global-styles/theme";
import { _get } from "../utils/services/api.service";

const dummyProps = require("../../dummy-props.json");

const ComponentsDisplay = () => {
  return (
    <>
    </>
  );
};

export default ComponentsDisplay;
