import React from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/core";

import { theme } from "../../../utils/global-styles/theme";

const SliderRightArrow = props => {
  return (
    <div
      css={css`
        position: absolute;
        right: 2%;
        top: 30%;
        cursor: pointer;
        z-index: 3;
      `}
      data-testId="rightArrow"
      onClick={props.onClick}
    >
      <svg width="92px" height="92px" className="svg-path-primary-fill">
        <g
          id="Explore-Lander"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Explore-Lander-Blue"
            transform="translate(-1319.000000, -3028.000000)"
          >
            <g id="Group-2" transform="translate(1319.000000, 3028.000000)">
              <circle id="Oval" fill="#FFFFFF" cx="46" cy="46" r="46"></circle>
              <g
                id="round-navigate_next-24px"
                transform="translate(6.000000, 6.000000)"
              >
                <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
                <path
                  d="M30.977221,21.9733965 C29.6742597,23.2656821
                 29.6742597,25.3532204 30.977221,26.645506 L43.9400152,
                 39.5020911 L30.977221,52.3586763 C29.6742597,53.6509619
                 29.6742597, 55.7385002 30.977221,57.0307858 C32.2801822,
                 58.3230714 34.3849658,58.3230714 35.6879271,57.0307858
                 L51.022779,41.8215781 C52.3257403,40.5292925 52.3257403,
                 38.4417542 51.022779,37.1494686 L35.6879271,21.940261 
                 C34.4183751,20.6811109 32.2801822,20.6811109 30.977221,
                 21.9733965 Z"
                  id="Path"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

SliderRightArrow.propTypes = {
  onClick: PropTypes.func,
};

export default SliderRightArrow;
