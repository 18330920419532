import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

import SliderLeftArrow from "./SliderLeftArrow/SliderLeftArrow";
import SliderRightArrow from "./SliderRightArrow/SliderRightArrow";
import BlogCard from "../BlogCard/BlogCard";

import { theme } from "../../utils/global-styles/theme";

export const SliderContainer = styled("div")`
  display: flex;
  position: relative;
  margin-top: 7em;

  h1 {
    position: absolute;
    z-index: 3;
    left: 6%;
    top: -14%;
  }

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
    h1 {
      top: -4%;
    }
  }
`;

export default class Slider extends Component {
  static propTypes = {
    title: PropTypes.string,
    slides: PropTypes.array,
  };
  constructor(props) {
    super(props);

    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      first: 0,
      second: 1,
      third: 2,
      displaySlides: [
        this.props.slides[0],
        this.props.slides[1],
        this.props.slides[2],
      ],
    };
  }

  goToPrevSlide() {
    let first = this.state.first;
    let second = this.state.second;
    let third = this.state.third;

    let { slides } = this.props;
    let slidesLength = slides.length;

    if (first < 1) {
      first = slidesLength;
    }

    if (second < 1) {
      second = slidesLength;
    }

    if (third < 1) {
      third = slidesLength;
    }

    --first;
    --second;
    --third;

    this.setState({
      first: first,
      second: second,
      third: third,
      displaySlides: [
        this.props.slides[first],
        this.props.slides[second],
        this.props.slides[third],
      ],
    });
  }

  goToNextSlide() {
    let first = this.state.first;
    let second = this.state.second;
    let third = this.state.third;

    let { slides } = this.props;
    let slidesLength = slides.length - 1;

    if (first === slidesLength) {
      first = -1;
    }
    if (second === slidesLength) {
      second = -1;
    }
    if (third === slidesLength) {
      third = -1;
    }

    ++first;
    ++second;
    ++third;

    this.setState({
      first: first,
      second: second,
      third: third,
      displaySlides: [
        this.props.slides[first],
        this.props.slides[second],
        this.props.slides[third],
      ],
    });
  }
  render() {
    const slidesToDisplay = this.state.displaySlides.map((slide, index) => (
      <BlogCard
        key={slide.title}
        image={slide.image}
        index={index}
        type={slide.type}
        title={slide.title}
        linkTitle={slide.linkTitle}
        imageDescription={slide.imageDescription}
        slug={slide.slug}
      />
    ));
    return (
      <SliderContainer>
        <h1>{this.props.title}</h1>
        <SliderLeftArrow onClick={this.goToPrevSlide} />
        {slidesToDisplay}
        <SliderRightArrow onClick={this.goToNextSlide} />
      </SliderContainer>
    );
  }
}
